import { Center } from '@chakra-ui/react';

function Footer() {
  return (
    <Center
      as="footer"
      h="50px"
      color="gray.400"
      fontSize={['md', null, null, 'lg']}
      textAlign="center"
    >
      Copyright &copy; {new Date().getFullYear()} Yayasan Pertiwi Kota Padang
    </Center>
  );
}

export default Footer;
