import { Center, CircularProgress, Text } from '@chakra-ui/react';
// import useSWR, { useSWRConfig } from 'swr';
import useSWR from 'swr';

import { sendData } from './fetching';

const fetcher = url => sendData(url, null, 'GET');

export const useFetchWithSwr = apiUrl => {
  // const { mutate } = useSWRConfig();
  const { data, error } = useSWR(apiUrl, fetcher, { revalidateOnFocus: false });

  return {
    // mutate,
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const fetchData = (
  apiRoute,
  opt,
  setLoading = null,
  setData = null,
  setError,
  setAlert = null,
  onClose = null
) => {
  fetch(apiRoute, opt)
    .then(res => {
      if (res.ok) {
        return res.json();
      }

      return res.json().then(err => {
        throw new Error(err.message || 'Cek Koneksi Anda');
      });
    })
    .then(result => {
      if (setLoading) setLoading(false);
      if (setData) setData(result);
      if (onClose) onClose();
      if (setAlert) setAlert();
    })
    .catch(error => {
      setError(error.message || 'Cek Koneksi Anda');
      setLoading(false);
    });
};

export const spinner = (
  <Center>
    <CircularProgress isIndeterminate value={30} size="70px" />
  </Center>
);

export const errorText = error => (
  <Center>
    <Text color="orange.500" fontWeight="semibold" fontSize="lg">
      {error}
    </Text>
  </Center>
);

export async function hitungJumlahMurid(dataMurid, db) {
  const total = dataMurid.length;

  const tk = await db
    .collection('murid')
    .find({ status: 1, jenjang: 'Tkit' })
    .count();

  const sd = await db
    .collection('murid')
    .find({ status: 1, jenjang: 'Sdit' })
    .count();

  const smp = await db
    .collection('murid')
    .find({ status: 1, jenjang: 'Smpit' })
    .count();

  return { total, tk, sd, smp };
}
