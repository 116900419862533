import React, { useState } from 'react';

const AlertContext = React.createContext({
  alert: {},
  setAlert: () => {},
});

export const AlertContextProvider = props => {
  const [alert, setAlert] = useState({
    active: false,
    status: '',
    title: '',
    description: '',
  });

  const setAlertHandler = val => {
    setAlert(val);
  };

  const value = {
    alert: alert,
    setAlert: setAlertHandler,
  };
  return (
    <AlertContext.Provider value={value}>
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
