import { Outlet } from 'react-router-dom';
import { Container } from '@chakra-ui/react';

export default function MyContainer() {
  return (
    <Container maxW={['sm', null, 'lg', '4xl', '5xl', '6xl']}>
      <Outlet />
    </Container>
  );
}
