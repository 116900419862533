import { Link as ReactLink } from 'react-router-dom';
import { ListIcon, ListItem, Link } from '@chakra-ui/react';

function SubMenu(props) {
  return (
    <Link as={ReactLink} to={props.link}>
      <ListItem p={3} mb={3} bg="gray.200" _hover={{ bg: 'gray.50' }}>
        <ListIcon as={props.icon} color="green.500" />
        {props.text}
      </ListItem>
    </Link>
  );
}

export default SubMenu;
