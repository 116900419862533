import { useContext } from 'react';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { AiOutlineMenuFold } from 'react-icons/ai';

import NavItem from './nav-item';
import AuthContext from '../../../store/auth-context';

function MenuHamburger() {
  const authCtx = useContext(AuthContext);
  return (
    <Menu>
      <MenuButton>
        <Icon as={AiOutlineMenuFold} w={8} h={8} color="orange.500" />
      </MenuButton>
      {!authCtx.isLoggedIn && (
        <MenuList align="center">
          {/* <MenuItem>
            <NavItem text="Home" link="/" />
          </MenuItem>
          <MenuItem>
            <NavItem text="TKIT" link="/daftar/tkit" />
          </MenuItem>
          <MenuItem>
            <NavItem text="SDIT" link="/daftar/sdit" />
          </MenuItem> */}
          {/* <MenuItem>
            <NavItem text="SMPIT" link="/daftar/smpit" />
          </MenuItem> */}
        </MenuList>
      )}

      {authCtx.isLoggedIn && (
        <MenuList align="center">
          <MenuItem>
            <NavItem text="Dashboard" link="/user/dashboard" />
          </MenuItem>
          {authCtx.isLoggedIn && authCtx.isAdmin && (
            <>
              <MenuItem>
                <NavItem text="Daftar Siswa" link="/admin/murid" />
              </MenuItem>
              <MenuItem>
                <NavItem text="Daftar Wali" link="/admin/wali" />
              </MenuItem>
              <MenuItem>
                <NavItem text="Pendaftar baru" link="/admin/ppdb" />
              </MenuItem>
              <MenuItem>
                <NavItem text="Unit sekolah" link="/admin/unit" />
              </MenuItem>
              <MenuItem>
                <NavItem text="F.A.Q" link="/admin/faq" />
              </MenuItem>
              <MenuItem>
                <NavItem text="Slide Carousel" link="/admin/carousel" />
              </MenuItem>
              {/* <MenuItem>
                <NavItem text="Aktifasi SPP" link="/admin/keuangan/spp" />
              </MenuItem>
              <MenuItem>
                <NavItem
                  text="Pembayaran SPP"
                  link="/admin/keuangan/transaksi/spp"
                />
              </MenuItem>
              <MenuItem>
                <NavItem text="Cek SPP" link="/admin/keuangan/cek-spp" />
              </MenuItem>
              <MenuItem>
                <NavItem text="Report VA" link="/admin/keuangan/report" />
              </MenuItem> */}
            </>
          )}

          {/* <MenuItem>
            <NavItem text="SPP" link="/user/spp-cek" />
          </MenuItem> */}
          <MenuItem>
            <NavItem text="Profil" link="/user/setting/profile" />
          </MenuItem>
          <MenuItem>
            <NavItem
              text="Ganti Password"
              link="/user/setting/change-password"
            />
          </MenuItem>
          <MenuItem onClick={() => authCtx.logout()} as="button">
            Logout
          </MenuItem>
        </MenuList>
      )}
    </Menu>
  );
}

export default MenuHamburger;
