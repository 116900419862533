import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
// import { hydrate, render } from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';

import App from './App';
import { AuthContextProvider } from './store/auth-context';
import { AlertContextProvider } from './store/alert-context';

const rootElement = document.getElementById('root');

const theme = extendTheme({
  fonts: {
    heading: 'poppins',
    body: 'poppins',
  },
});

createRoot(rootElement).render(
  <StrictMode>
    <BrowserRouter>
      <AuthContextProvider>
        <AlertContextProvider>
          <ChakraProvider theme={theme}>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </ChakraProvider>
        </AlertContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </StrictMode>
);

// if (root.hasChildNodes()) {
//   hydrate(
//     <BrowserRouter>
//       <AuthContextProvider>
//         <AlertContextProvider>
//           <ChakraProvider theme={theme}>
//             <Routes>
//               <Route path="/*" element={<App />} />
//             </Routes>
//           </ChakraProvider>
//         </AlertContextProvider>
//       </AuthContextProvider>
//     </BrowserRouter>,
//     root
//   );
// } else {
//   render(
//     <BrowserRouter>
//       <AuthContextProvider>
//         <AlertContextProvider>
//           <ChakraProvider theme={theme}>
//             <Routes>
//               <Route path="/*" element={<App />} />
//             </Routes>
//           </ChakraProvider>
//         </AlertContextProvider>
//       </AuthContextProvider>
//     </BrowserRouter>,
//     root
//   );
// }
