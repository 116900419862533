import { useContext } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Button, Flex, Box, Link } from '@chakra-ui/react';

import Logo from '../../ui/logo/logo';
import MenuHamburger from './menu-hamburger';
import NavItem from './nav-item';
import AuthContext from '../../../store/auth-context';

function Header() {
  const authCtx = useContext(AuthContext);

  return (
    <>
      <Flex as="header" py={10} justify="space-between" align="center">
        <Flex
          as="nav"
          align="center"
          fontSize="lg"
          display={[null, null, null, 'none']}
        >
          {/* <Logo height="100px" width="100px" /> */}
          {authCtx.isLoggedIn && (
            <Box>
              <MenuHamburger />
            </Box>
          )}
        </Flex>

        <NavItem text={<Logo height="100px" width="100px" />} link="/" />

        {!authCtx.isLoggedIn && (
          <Link as={ReactLink} to="/auth/login">
            <Button colorScheme="orange" size="lg" fontSize="xl">
              Log in
            </Button>
          </Link>
        )}

        {authCtx.isLoggedIn && (
          <Link as={ReactLink} to="/user/dashboard">
            <Button colorScheme="orange" size="sm" fontSize="sm">
              Dashboard
            </Button>
          </Link>
        )}
      </Flex>
    </>
  );
}

export default Header;
