import { useContext } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

import AuthContext from '../../store/auth-context';

function Protected() {
  const location = useLocation();

  const { isLoggedIn } = useContext(AuthContext);

  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/auth/login" state={{ from: location }} replace />
  );
}

export default Protected;
