import { Link as ReactLink } from 'react-router-dom';
import { Link } from '@chakra-ui/react';

function NavItem(props) {
  return (
    <Link
      as={ReactLink}
      to={props.link}
      ml={5}
      fontWeight={['light', null, null, 'semi-bold']}
    >
      {props.text}
    </Link>
  );
}

export default NavItem;
