import { useContext } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

import AuthContext from '../../store/auth-context';

function ProtectAdmin() {
  const location = useLocation();

  const { isLoggedIn, isAdmin } = useContext(AuthContext);

  return isLoggedIn && isAdmin ? (
    <Outlet />
  ) : (
    <Navigate to="/auth/login" state={{ from: location }} replace />
  );
}

export default ProtectAdmin;
