import { useState } from 'react';
import { useLocation, Link as ReactLink } from 'react-router-dom';
import { Flex, IconButton, List, Text, Link } from '@chakra-ui/react';
import SubMenu from './sidebar-submenu';

const active = {
  bg: '#fff',
  borderRadius: 'lg',
  shadow: 'md',
  // w: '130%',
  // ml: 1,
};

function SidebarItem(props) {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  const clickHandler = () => {
    setIsOpen(prev => !prev);
  };

  const activeClass = props.link === location.pathname ? active : '';

  return (
    <>
      {!props.type && (
        <Link as={ReactLink} to={props.link}>
          <Flex
            direction="row"
            align="center"
            p={5}
            justify="flex-start"
            mb={1}
            {...activeClass}
          >
            <IconButton colorScheme="teal" icon={props.icon} size="sm" />
            <Text ml={3} fontWeight="semibold">
              {props.text}
            </Text>
          </Flex>
        </Link>
      )}

      {props.type && (
        <>
          <Flex
            direction="row"
            align="center"
            p={5}
            justify="flex-start"
            mb={1}
            {...activeClass}
            onClick={clickHandler}
            _hover={{ cursor: 'pointer' }}
          >
            <IconButton colorScheme="teal" icon={props.icon} size="sm" />
            <Text ml={3} fontWeight="semibold">
              {props.text}
            </Text>
          </Flex>
          {isOpen && (
            <List spacing={3} pl={5}>
              {props.subMenu.map(sub => (
                <SubMenu
                  key={sub.link}
                  icon={sub.icon}
                  text={sub.text}
                  link={sub.link}
                />
              ))}
            </List>
          )}
        </>
      )}
    </>
  );
}

export default SidebarItem;
